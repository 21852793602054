import styled from '@emotion/styled'
// import { over } from '@theme/media'
import r from '@utils/pxToRem'

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${r`0 30px`};
  text-align: center;
  max-width: 20ch;
  margin: 0 auto;
`

const LottieContainer = styled.div`
  width: 200px;
  height: 228px;
`

const StepBody = styled.div`
  opacity: 0;
  transform: translateY(40px);
  transition: opacity, transform, 1s;
`

const StepTitle = styled.h3`
  font-size: ${r`16px`};
  line-height: 1.3;
`

const StepCount = styled.span`
  color: ${({ theme }) => theme.colors.purple};
  text-transform: uppercase;
  font-size: ${r`12px`};
  font-weight: bold;
  line-height: 2;
`

const StepDescription = styled.span`
  & > .rich-text > p {
    font-size: ${r`14px`};
    line-height: 1.35;
  }
`

export { StepContainer, LottieContainer, StepBody, StepTitle, StepCount, StepDescription }
