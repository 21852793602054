import styled from '@emotion/styled'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const StepsSectionContainer = styled.section`
  background: ${({ theme }) => theme.colors.white};
`

const StepsSectionWrapper = styled.section`
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  color: ${({ theme }) => theme.colors.textBlack};
  margin: 0 auto;
  padding: ${r`32px 16px 48px`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};

  & > h2 {
    font-family: ${({ theme }) => theme.fonts.graphik};
    font-size: ${r`27px`};
    margin-bottom: ${r`8px`};
    text-align: center;

    strong {
      font-family: ${({ theme }) => theme.fonts.graphik};
      font-weight: 600;
    }

    ${({ theme }) =>
      over.tablet`
      font-size: ${theme.fontSizes.heading[0]};
    `};
  }

  & > span {
    font-family: ${({ theme }) => theme.fonts.graphik};
    font-size: ${({ theme }) => theme.fontSizes.headingDescription[1]};
    line-height: 1.3;

    ${({ theme }) =>
      over.tablet`
      font-size: ${theme.fontSizes.headingDescription[0]};
    `};
  }

  ${over.tablet`
    padding: ${r`48px 16px 80px`};
  `}
`

const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${r`32px`};
  padding: ${r`32px 16px 0`};

  ${over.thone`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${over.desktop`
    grid-template-columns: repeat(4, 1fr);
  `}
`

export { StepsSectionContainer, StepsSectionWrapper, StepsContainer }
