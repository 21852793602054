import React from 'react'
import Step from '@components/Step'
import { ContentfulStep } from 'typings/contentful-queries'
import { StepsSectionContainer, StepsSectionWrapper, StepsContainer } from './Styles'

interface StepsSectionProps {
  heading: string
  data: ContentfulStep[]
}

const StepsSection: React.FC<StepsSectionProps> = ({ heading, data }) => {
  return (
    <StepsSectionContainer>
      <StepsSectionWrapper>
        <h2
          dangerouslySetInnerHTML={{
            __html: heading ?? '',
          }}
        />
        <StepsContainer>
          {data.map((step, index) => (
            <Step data={step} key={step.title} index={index} />
          ))}
        </StepsContainer>
      </StepsSectionWrapper>
    </StepsSectionContainer>
  )
}

export default StepsSection
