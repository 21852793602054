import styled from '@emotion/styled'
import { under, over } from '@theme/media'
import r from '@utils/pxToRem'

const CheckboxLabel = styled.label`
  display: inline-flex;
  align-items: baseline;
  color: ${({ theme }) => theme.colors.textBlack};

  p {
    margin-bottom: 0;
    max-width: 50ch;
    text-align: center;
    font-size: ${r`14px`};
  }

  /* ${under.thone`
    p {text-align: left;}
    max-width: 30ch;
  `} */
`

const CheckboxContainer = styled.span`
  [data-reach-custom-checkbox-container]{
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    top: 3px;
    margin-right: 1rem;
    ${over.thone`
      margin-right: 0.5rem;
  `}

  }
  [data-reach-custom-checkbox-container]::before,
  [data-reach-custom-checkbox-container]::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    content: '';
  }

  [data-reach-custom-checkbox-container]::before {
    width: 1rem;
    height: 1rem;
    border: 2px solid  ${({ theme }) => theme.colors.mediumGrey};
    border-radius: 3px;
    background-color:  ${({ theme }) => theme.colors.white};
  }

  [data-reach-custom-checkbox-container]:focus-within {
    outline: none;
    box-shadow: none;
  }

  /* [data-reach-custom-checkbox-container]:focus-within::before {
    box-shadow: 0 0 0 2px rgba(63, 148, 191, 0.65);
  } */

  [data-reach-custom-checkbox-container][data-state='checked']::before,
  [data-reach-custom-checkbox-container][data-state='mixed']::before {
    border-color: ${({ theme }) => theme.colors.purple};
    background-color: ${({ theme }) => theme.colors.purple};
  }
  [data-reach-custom-checkbox-container][data-state='checked']::after {
    display: block;
    width: 13px;
    height: 11px;
    /* width: calc(45% - 2px);
    height: calc(90% - 4px);
    border: medium solid #fff;
    border-width: 0 0.125em 0.125em 0;
    transform: translateY(-65%) translateX(-50%) rotate(45deg) skew(5deg); */
    transform: translateY(-50%) translateX(30%);
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25 4.9375L5.25 9.4375L11.75 1.5625' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }

  [data-reach-custom-checkbox-container][data-state='mixed']::after {
    display: block;
    width: calc(100% - 6px);
    border-bottom: 0.125em solid #fff;
    transform: translate(-50%, -50%);
    transform-origin: center center;
  }

  [data-reach-custom-checkbox][data-custom-checkmarks]::after {
    display: none;
  }
`

export { CheckboxContainer, CheckboxLabel }
