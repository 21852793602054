import React, { useEffect, useRef } from 'react'
import useLocalStorage from 'use-local-storage'
import { useAtom } from 'jotai'
import { useAccordionContext } from '@reach/accordion'
import { ContentfulAccordionBodyText, ContentfulConstants, Maybe } from 'typings/contentful-queries'
import { AccordionItem } from '@reach/accordion'
import RichText from '@components/RichText'
import Chevron from '@components/Chevron'
import Feedback from '@components/Feedback'
import { changeSourceAtom } from '@state/index'
import { useDivHeight } from '@utils/useDivHeights'

import {
  AccordionHeaderContainer,
  AccordionHeader,
  AccordionPanel,
  AccordionButtonContainer,
} from './Styles'

export interface AccordionProps {
  accordionIndex: number
  title: string
  anchor: string
  body: string | ContentfulAccordionBodyText
  seeMoreLabel: string
  seeLessLabel: string
  toggleItem: (toggledIndex: number) => void
  feedback: {
    feedbackQuestion?: Maybe<string>
    thankYouMessage?: Maybe<string>
    miscLabels?: Maybe<ContentfulConstants>
  }
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  anchor,
  body,
  accordionIndex,
  seeMoreLabel,
  seeLessLabel,
  toggleItem,
  feedback,
}) => {
  const [isFeedbackSumbitted] = useLocalStorage(anchor, false)
  const isBrowser = typeof window !== 'undefined'
  const { openPanels } = useAccordionContext()
  const [changeSource, setChangeSource] = useAtom(changeSourceAtom)
  const appendAnchor = () => {
    setChangeSource('header')
    if (window.location.hash !== `#${anchor}`) {
      if (window.history.pushState) {
        window.history.pushState(null, '', `#${anchor}`)
      } else {
        window.location.hash = `#${anchor}`
      }
    }
  }

  useEffect(() => {
    if (
      // Check to see if window object is available
      isBrowser &&
      // Check if the path in address bar matches the id of the current accordion
      location.hash.endsWith(anchor) &&
      // Check if the source of the accordion state toggle was NOT from the header button of the accordion (coming from a link or direct address entry)
      changeSource !== 'header' &&
      // Check to see if the panel wasn't already open
      !openPanels.includes(accordionIndex)
    ) {
      toggleItem(accordionIndex)
    }
  }, [changeSource])

  const accordionBody = useRef<HTMLDivElement>(null)
  const { height } = useDivHeight(accordionBody)

  return (
    <AccordionItem>
      <AccordionHeaderContainer id={anchor} onClick={appendAnchor} onKeyDown={appendAnchor}>
        <AccordionHeader className={`${anchor}-button`}>
          <span id={`${anchor}-title`}>{title}</span>
          <AccordionButtonContainer
            id={
              openPanels.includes(accordionIndex)
                ? `accordion-${accordionIndex + 1}-controls-container-see-less`
                : `accordion-${accordionIndex + 1}-controls-container-see-more`
            }
          >
            <p id={`accordion-${accordionIndex + 1}-see-more-label`} className="collapsedLabel">
              {seeMoreLabel}
            </p>
            <p id={`accordion-${accordionIndex + 1}-see-less-label`} className="openLabel">
              {seeLessLabel}
            </p>
            <Chevron id={`${anchor}-icon`} size={30} />
          </AccordionButtonContainer>
        </AccordionHeader>
      </AccordionHeaderContainer>
      <AccordionPanel height={`${height}px`}>
        <div className="panel-wrapper" ref={accordionBody}>
          <RichText data={body} />
          {!isFeedbackSumbitted && <Feedback data={feedback} anchor={anchor} />}
        </div>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default Accordion
