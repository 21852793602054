import styled from '@emotion/styled'
import { over, under } from '@theme/media'
import r from '@utils/pxToRem'

const RenewalWizardSectionContainer = styled.section`
  text-align: center;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  padding: ${r`40px 16px`};
  background: ${({ theme }) => theme.colors.white};

  & > .rich-text {
    color: ${({ theme }) => theme.colors.textBlack};

    & > p {
      margin-bottom: ${r`40px`};

      &:last-of-type {
        margin-bottom: ${r`0px`};
      }
    }
  }

  ${over.tablet`
    padding: ${r`40px 16px`};
  `}

  ${over.laptop`
    padding: ${r`80px 16px`};
  `}
`

const QuestionWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textBlack};

  & > p {
    margin-bottom: ${r`40px`};

    &:last-of-type {
      margin-bottom: ${r`0px`};
    }
  }

  ${over.tablet`
    padding: ${r`16px 16px`};
  `}

  ${over.laptop`
    padding: ${r`24px 16px`};
  `}
`

const WizardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: ${r`40px`}; */

  & > img {
    margin-top: ${r`40px`};
    margin-bottom: ${r`40px`};
  }

  & > h4 {
    padding-top: 5px;
    font-family: ${({ theme }) => theme.fonts.tondo};
    font-size: ${({ theme }) => theme.fontSizes.failureHeading[0]};
    line-height: 1.34;
    margin-bottom: ${r`24px`};
  }

  & > p {
    margin-bottom: 1rem;
  }

  & > span > p {
    font-size: ${({ theme }) => theme.fontSizes.skipText[0]};
  }

  & > button {
    margin-bottom: ${r`40px`};
  }

  & > .rich-text {
    max-width: 53ch;

    & > h1,h2,h3,h4,h5,h6 {
      font-family: ${({ theme }) => theme.fonts.tondo};
    }

    & > h1 {
      font-size: ${r`45px`};
      line-height: ${r`50px`};
      margin-bottom: ${r`40px`};
    }

    & > h2 {
      font-size: ${r`24px`};
      line-height: ${r`29px`};
      margin-bottom: ${r`30px`};
    }

    & > h3 {
      font-size: ${r`18px`};
      line-height: ${r`24px`};
      margin-bottom: ${r`25px`};
    }

    & > h4 {
      font-size: ${r`16px`};
      line-height: ${r`24px`};
      margin-bottom: ${r`25px`};
    }

    & > p {
      margin-bottom: ${r`16px`};

      &:last-of-type {
        margin-bottom: ${r`40px`};
      }
    }
  }
`

const Option = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${r`24px`};
  padding: ${r`0 30px`};

  & > h5 {
    font-family: ${({ theme }) => theme.fonts.tondo};
    font-size: ${({ theme }) => theme.fontSizes.body[0]};
    line-height: 1.67;
  }

  & > span {
    font-size: ${({ theme }) => theme.fontSizes.questionCounter[0]};
    line-height: 2;
    margin-bottom: ${r`20px`};
  }

  & > a {
    max-width: 18ch;
  }

  & > .rich-text {
    /* max-width: 53ch; */

    & > p {
      margin-bottom: ${r`0px`};

      &:last-of-type {
        margin-bottom: ${r`8px`};
      }
    }
  }

  ${over.tablet`
    padding: ${r`0 30px`};
  `}

  ${over.tabletLarge`
    padding: ${r`0 70px`};
  `}

  ${over.laptop`
    padding: ${r`0 110px`};
  `}
`

const OptionsContainer = styled.div<{ disabled: boolean }>`
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: repeat(1);
  /* align-items: center; */
  justify-content: center;
  margin: ${r`72px 0 0px`};

  ${props =>
    props.disabled &&
    `
    filter: saturate(0);
    opacity: 0.5;
    pointer-events: none;
  `}

  ${Option} {
    border-right: 1px solid ${({ theme }) => theme.colors.mediumGrey};

    &:last-of-type {
      border: none;
    }

    ${under.tablet`
      border: none;
      padding-bottom: 40px;

      &:last-of-type {
        padding-bottom: 0px;
      }
    `}
  }

  ${over.tablet`
    grid-template-columns: repeat(auto-fit, minmax(100px, 50%));
  `}
`

const SkipButtonLink = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.graphik};
  font-size: ${({ theme }) => theme.fontSizes.skipText};
  color: ${({ theme }) => theme.colors.linkBlue};
  background: transparent;
  line-height: 1.6;
  text-decoration: none;
  transition: all 0.15s;
  cursor: pointer;
  border: none;

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
    text-decoration: underline;
  }
`

const SkipLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.graphik};
  font-size: ${({ theme }) => theme.fontSizes.skipText};
  color: ${({ theme }) => theme.colors.linkBlue};
  line-height: 1.6;
  text-decoration: none;
  transition: all 0.15s;

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
    text-decoration: underline;
  }
`

const OptionBrand = styled.img`
  padding: ${r`24px`};
`

export { RenewalWizardSectionContainer, QuestionWrapper, WizardContainer, OptionsContainer, Option, OptionBrand, SkipButtonLink, SkipLink }
