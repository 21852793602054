import styled from '@emotion/styled'
import { under } from '@theme/media'
import r from '@utils/pxToRem'

const MainWrapper = styled.main`
  min-height: calc(100vh - 394px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${r`20px`};
  box-sizing: border-box;

  ${under.laptop`
    min-height: calc(100vh - 377px)
  `}
  
  ${under.tablet`
    flex-direction: column-reverse;
  `}

  & > div {
    max-width: ${r`640px`};
  }

  h1 {
    font-family: ${({ theme }) => theme.fonts.tondo};
    font-size: ${r`54px`};
    line-height: ${r`54px`};
    margin-bottom: ${r`40px`};

    ${under.tablet`
      font-size: ${r`32px`};
      line-height: ${r`35px`};
    `}
  }

  h2 {
    font-size: ${r`27px`};
    line-height: ${r`31px`};
    margin-bottom: ${r`40px`};

    ${under.tablet`
      font-size: ${r`18px`};
      line-height: ${r`24px`};
      margin-bottom: ${r`24px`};
    `}    
  }

  & > img {
    padding-left: ${r`144px`};

    ${under.laptop`
      padding-left: ${r`72px`};
    `}

    ${under.tablet`
      padding-left: ${r`0px`};
      margin-bottom: ${r`40px`};
      width: 15vh;
    `}
  }
`

export default MainWrapper
