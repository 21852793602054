import styled from '@emotion/styled'
import { over, under } from '@theme/media'
import r from '@utils/pxToRem'

const AccordionSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.lightBlue};

  ${over.laptop`
    padding: ${r`100px 0 130px 0`};
  `}

  ${({ theme }) =>
    under.laptop`
    margin: ${r`0 31px`};;
    padding: ${r`0px`};
    background: ${theme.colors.white};
    border-style: solid;
    border-width: ${r`0 0 1px 0`};
    border-color: ${theme.colors.lightGrey};
  `};

  [data-reach-accordion-item] {
    ${over.tablet`
      padding: ${r`0 20px`};
    `}
  }
`

export default AccordionSectionContainer
