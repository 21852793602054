import React from 'react'
import { HeroSectionContainer, HeroSectionWrapper } from './Styles'

interface HeroSectionProps {
  introHeader: string
  introDescription: string
}

const HeroSection: React.FC<HeroSectionProps> = ({ introHeader, introDescription }) => (
  <HeroSectionContainer>
    <HeroSectionWrapper>
      <h1>{introHeader}</h1>
      <span>{introDescription}</span>
    </HeroSectionWrapper>
  </HeroSectionContainer>
)

export default HeroSection
