import styled from '@emotion/styled'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const HeroSectionContainer = styled.section`
  background: ${({ theme }) => theme.colors.purple};
`

const HeroSectionWrapper = styled.section`
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  color: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  padding: ${r`32px 24px 32px`};
  text-align: center;
  box-sizing: border-box;

  & > h1 {
    font-family: ${({ theme }) => theme.fonts.tondo};
    font-size: ${({ theme }) => theme.fontSizes.heading[1]};
    margin-bottom: ${r`8px`};

    ${({ theme }) =>
      over.tablet`
      font-size: ${theme.fontSizes.heading[0]};
    `};
  }

  & > span {
    font-family: ${({ theme }) => theme.fonts.graphik};
    font-size: ${({ theme }) => theme.fontSizes.headingDescription[1]};
    line-height: 1.3;

    ${({ theme }) =>
      over.tablet`
      font-size: ${theme.fontSizes.headingDescription[0]};
    `};
  }

  ${over.tablet`
    padding: ${r`48px 24px`};
    text-align: left;
  `}
`

export { HeroSectionContainer, HeroSectionWrapper }
