import React, { useRef, useEffect, useState } from 'react'
import lottie from 'lottie-web'
import { useMediaQuery } from '@react-hook/media-query'
import useIntersectionObserver from '@react-hook/intersection-observer'
import RichText from '@components/RichText'
import { ContentfulStep } from 'typings/contentful-queries'
import { StepContainer, LottieContainer, StepBody, StepTitle, StepCount, StepDescription } from './Styles'

const LOTTIE_STAGGER_DELAY =1200

interface StepProps {
  data: ContentfulStep
  index: number
}

const Step: React.FC<StepProps> = ({ data, index }) => {
  const { stepNumberKeyword, stepNumberOverride, title, description, illustrationAltText, lottieData } = data
  const lottieId = index.toString()
  const isMobile = useMediaQuery('only screen and (max-width: 600px)')
  const container = useRef(null)
  const { isIntersecting } = useIntersectionObserver(container, { threshold: 0.4 })
  const [hasTriggered, setHasTriggered] = useState(false)
  const StepBodyRef = useRef<any>(null);

  useEffect(() => {
    if (isMobile && isIntersecting) {
      setHasTriggered(true)
    }
  }, [isIntersecting])

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current as any,
      renderer: 'canvas' as any,
      loop: false,
      autoplay: false,
      animationData: JSON.parse(lottieData?.internal?.content ?? ''),
      name: lottieId,
    })

    return () => {
      lottie.destroy(lottieId)
    }
  }, [])

  useEffect(() => {
    if (isMobile && isIntersecting) {
      lottie.play(lottieId)
      StepBodyRef.current.style.opacity = '1'
      StepBodyRef.current.style.transform = 'translate(0)'
    }
  }, [hasTriggered])

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        lottie.play(lottieId)
        StepBodyRef.current.style.opacity = '1'
        StepBodyRef.current.style.transform = 'translate(0)'
      }, index * LOTTIE_STAGGER_DELAY)
    }
  }, [])

  return (
    <StepContainer>
      <LottieContainer
        ref={container}
        aria-label={illustrationAltText ?? ''}
        // onMouseEnter={() => lottie.play(lottieId)}
        // onMouseLeave={() => lottie.pause(lottieId)}
      />
      <StepBody ref={StepBodyRef}>
        <StepCount>
          {stepNumberOverride ?? `${stepNumberKeyword} ${index + 1}`}
        </StepCount>
        <StepTitle>{title}</StepTitle>
        <StepDescription>
          <RichText data={description} />
        </StepDescription>
      </StepBody>
    </StepContainer>
  )
}

export default Step
