import React from 'react'
import { CustomCheckbox, CustomCheckboxProps } from '@reach/checkbox'
import '@reach/checkbox/styles.css'
import { CheckboxContainer, CheckboxLabel } from './Styles'

interface CheckboxProps extends CustomCheckboxProps {
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ className, children, ...props }) => {
  return (
    <CheckboxContainer><CheckboxLabel className={className}>
    <CustomCheckbox {...props} />
    {children}
  </CheckboxLabel></CheckboxContainer>
    
  )
}

export default Checkbox
