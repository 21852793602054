import React from 'react'
import { Svg } from './Styles'

const CheckmarkIcon: React.FC = () => {
  return (
    <Svg width="1.5em" height="1.5em" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27" cy="27" r="27" fill="#3ADDA2" />
      <path
        fill="none"
        d="M17 25.0345L23.5882 33L36 22"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default CheckmarkIcon
