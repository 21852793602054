import React, { useState } from 'react'
import RichText from '@components/RichText'
import Button from '@components/Button'
import Question from '@components/Question'
import Checkbox from '@components/Checkbox'
import { ContentfulWizard, ContentfulWizardQuestion } from 'typings/contentful-queries'
import {
  RenewalWizardSectionContainer,
  WizardContainer,
  OptionsContainer,
  Option,
  SkipButtonLink,
  SkipLink,
  OptionBrand,
} from './Styles'

const SUCCESS_OPTION_LABEL_DISPLAY_THRESHOLD = 3

export interface RenewalWizardSectionProps {
  data: ContentfulWizard
}

const RenewalWizardSection: React.FC<RenewalWizardSectionProps> = ({ data }) => {
  const {
    initialStep,
    skipText,
    skipLinkText,
    skipQuestionsLinkUrl,
    ctaLabel,
    questions,
    successHeading,
    successDescription,
    legalCheckboxText,
    successIcon,
    successOptions,
    optionLabel,
    successRedirectUrl,
  } = data

  const [currentStep, setCurrentStep] = useState<string>('START')
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSkipped, setHasSkipped] = useState(false)
  const [checked, setChecked] = useState(false)

  const [q1, q2, q3] = questions as ContentfulWizardQuestion[]

  const onReset = () => {
    setHasFailed(false)
    setCurrentStep('Q1')
  }

  const onSkip = () => {
    setHasSkipped(true)
    setCurrentStep('SUCCESS')
  }

  const isClient = () => typeof window === 'object'

  const handleSuccess = () => {
    if (successRedirectUrl && successRedirectUrl !== '#original-success' && isClient()) {
      window.location.href = successRedirectUrl
    } else {
      setCurrentStep('SUCCESS')
    }
  }

  const StepRenderer: React.FC<{ currentStep: string }> = ({ currentStep }) => {
    switch (currentStep) {
      case 'Q1':
        return (
          <Question
            number="1"
            total="3"
            content={q1}
            onYesClick={() => setCurrentStep('Q2')}
            onNoClick={() => setHasFailed(true)}
            onReset={onReset}
            onSkip={onSkip}
            hasFailed={hasFailed}
            canReset={false}
          />
        )
      case 'Q2':
        return (
          <Question
            number="2"
            total="3"
            content={q2}
            onYesClick={() => setHasFailed(true)}
            onNoClick={() => setCurrentStep('Q3')}
            onReset={onReset}
            onSkip={onSkip}
            hasFailed={hasFailed}
          />
        )
      case 'Q3':
        return (
          <Question
            number="3"
            total="3"
            content={q3}
            onYesClick={() => handleSuccess()}
            onNoClick={() => setHasFailed(true)}
            onReset={onReset}
            onSkip={onSkip}
            hasFailed={hasFailed}
          />
        )
      case 'SUCCESS':
        return (
          <WizardContainer>
            <img src={successIcon?.file?.url ?? ''} alt={successIcon?.description ?? ''} />
            {!hasSkipped && <h4>{successHeading}</h4>}
            <RichText data={successDescription} />
            <Checkbox
              name="terms-and-conditions"
              value="agree"
              checked={checked}
              onChange={event => {
                setChecked(event.target.checked)
              }}
            >
              <RichText data={legalCheckboxText} />
            </Checkbox>
            <OptionsContainer disabled={!checked}>
              {successOptions?.map((option, index) => (
                <Option key={option?.name}>
                  {successOptions.length >= SUCCESS_OPTION_LABEL_DISPLAY_THRESHOLD && (
                    <span>
                      {optionLabel} {index + 1}
                    </span>
                  )}
                  <h5>{option?.name}</h5>
                  <RichText data={option?.optionBody} />
                  <Button
                    id={`option${index + 1}-log-in-button`}
                    href={option?.cta?.destination ?? ''}
                  >
                    {option?.cta?.text}
                  </Button>
                  <OptionBrand
                    src={option?.brandLogo?.file?.url ?? ''}
                    alt={option?.brandLogo?.description ?? ''}
                  />
                </Option>
              ))}
            </OptionsContainer>
          </WizardContainer>
        )
      case 'START':
      default:
        return (
          <WizardContainer>
            <RichText data={initialStep} />
            <Button onClick={() => setCurrentStep('Q1')}>{ctaLabel}</Button>
            <span>{skipText}</span>
            {skipQuestionsLinkUrl && skipQuestionsLinkUrl !== '#skip-wizard' ? (
              <SkipLink href={skipQuestionsLinkUrl}>
                {skipLinkText}
              </SkipLink>
            ) : (
              <SkipButtonLink onClick={onSkip}>{skipLinkText}</SkipButtonLink>
            )}
          </WizardContainer>
        )
    }
  }

  return (
    <RenewalWizardSectionContainer id="renewal-wizard">
      <StepRenderer currentStep={currentStep} />
    </RenewalWizardSectionContainer>
  )
}

export default RenewalWizardSection
