import React from 'react'
import { INLINES } from '@contentful/rich-text-types'
import RichText from '@components/RichText'
import Button from '@components/Button'
import ResetIcon from '@components/ResetIcon'
import { ContentfulWizardQuestion } from 'typings/contentful-queries'
import {
  QuestionContainer,
  ButtonsContainer,
  QuestionCounter,
  FailureContainer,
  ResetButton,
} from './Styles'

interface QuestionProps {
  number: string
  total: string
  hasFailed: boolean
  content: ContentfulWizardQuestion
  onYesClick: () => void
  onNoClick: () => void
  onReset: () => void
  onSkip: () => void
  canReset?: boolean
}

const Question: React.FC<QuestionProps> = ({
  content,
  hasFailed,
  onYesClick,
  onNoClick,
  onReset,
  onSkip,
  number,
  total,
  canReset = true,
}) => {
  const { questionText, skipText, miscLabels, failureTitle, failureBody, failureIcon } = content

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node: any) => {
        if (node.data.uri === '#skip-wizard') {
          return (
            <ResetButton onClick={onSkip}>{node.content[0].value}</ResetButton>
          )
        }
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {node.content[0].value}
          </a>
        )
      },
    },
  }

  return (
    <>
      <QuestionContainer hasFailed={hasFailed}>
        <QuestionCounter>
          {miscLabels?.currentPosition?.replace('$CURRENT', number).replace('$TOTAL', total)}
        </QuestionCounter>
        <RichText data={questionText} />
        <ButtonsContainer>
          <Button id={`q${number}-no`} variant="outline" onClick={onNoClick}>
            {miscLabels?.no}
          </Button>
          <Button id={`q${number}-yes`} variant="outline" onClick={onYesClick}>
            {miscLabels?.yes}
          </Button>
        </ButtonsContainer>
        {!hasFailed && <RichText data={skipText} options={options} />}
        {canReset && !hasFailed && (
          <ResetButton onClick={onReset}>
            <span>Start over</span>
            <ResetIcon />
          </ResetButton>
        )}
      </QuestionContainer>
      {hasFailed && (
        <FailureContainer>
          <img src={failureIcon?.file?.url ?? ''} alt={failureIcon?.description ?? ''} />
          <h4>{failureTitle}</h4>
          <RichText data={failureBody} />
          <ResetButton onClick={onReset}>
            <span>Start over</span>
            <ResetIcon />
          </ResetButton>
        </FailureContainer>
      )}
    </>
  )
}

export default Question
