import React, { useState } from 'react'
import { Accordion as ReachAccordion } from '@reach/accordion'
import { ContentfulAccordion } from 'typings/contentful-queries'
import Accordion from '@components/Accordion'
import '@reach/accordion/styles.css'
import AccordionSectionContainer from './Styles'

export interface AccordionSectionProps {
  data: ContentfulAccordion[]
}

const AccordionSection: React.FC<AccordionSectionProps> = ({ data: Accordions }) => {
  const [indices, setIndices] = useState([-100])
  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter(currentIndex => currentIndex !== toggledIndex))
    } else {
      setIndices([...indices, toggledIndex].sort())
    }
  }

  return (
    <AccordionSectionContainer id="accordions">
      <ReachAccordion index={indices} onChange={toggleItem}>
        {Accordions.map((accordion, i) => {
          const { anchor, title, bodyText, feedbackQuestion, thankYouMessage, miscLabels } = accordion
          const feedback = {feedbackQuestion, thankYouMessage, miscLabels}
          return (
          <Accordion
            accordionIndex={i}
            key={anchor ?? ''}
            title={title ?? ''}
            anchor={anchor ?? ''}
            body={bodyText ?? ''}
            toggleItem={toggleItem}
            seeMoreLabel={miscLabels?.seeMore ?? ''}
            seeLessLabel={miscLabels?.seeLess ?? ''}
            feedback={feedback}
          />
        )})}
      </ReachAccordion>
    </AccordionSectionContainer>
  )
}

export default AccordionSection
