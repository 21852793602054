import styled from '@emotion/styled'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const Svg = styled.svg`
  fill: ${({ theme }) => theme.colors.blue};
  padding-right: 8px;
`

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: 100px;
  transition: 0.5s ease-in-out;
  transition-delay: 2.5s;
  overflow: hidden;

  .fade-in {
    opacity: 1;
    visibility: visible;
  }

  .fade-out {
    opacity: 0;
  }

  &.hide {
    max-height: 0;
  }
`

const FeedbackQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${over.phablet`
    flex-direction: row;
  `}
`

const FeedbackButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    font-size: ${({ theme }) => theme.fontSizes.questionCounter[0]};
    padding: ${r`14px 28px`};
  }

  & > button {
    &:first-of-type {
      margin-right: ${r`20px`};
    }
  }
`

const FeedbackQuestion = styled.span`
  font-family: ${({ theme }) => theme.fonts.graphik};
  font-size: ${({ theme }) => theme.fontSizes.questionCounter[0]};
  /* font-weight: bold; */
  margin: ${r`0 0 24px 0`};
  transition: 0.5s ease-in-out;
  opacity: 1;

  ${over.phablet`
    margin: ${r`0 40px 0 0`};
  `}
`

const ThankYouMessage = styled.span`
  display: inline-flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.graphik};
  font-size: ${({ theme }) => theme.fontSizes.questionCounter[0]};
  /* font-weight: bold; */
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: ${r`20px 20px`};
  transition: 0.5s ease-in-out;
`

export { Svg, FeedbackContainer, FeedbackQuestionContainer, FeedbackButtonsContainer, FeedbackQuestion, ThankYouMessage }
