import React, { useState } from 'react'
import useLocalStorage from 'use-local-storage'
import Button from '@components/Button'
import { ContentfulConstants, Maybe } from 'typings/contentful-queries'
import CheckmarkIcon from './CheckmarkIcon'
import {
  FeedbackContainer,
  FeedbackButtonsContainer,
  FeedbackQuestion,
  ThankYouMessage,
  FeedbackQuestionContainer,
} from './Styles'

interface FeedbackProps {
  data: {
    feedbackQuestion?: Maybe<string>
    thankYouMessage?: Maybe<string>
    miscLabels?: Maybe<ContentfulConstants>
  }
  anchor: string
}

const Feedback: React.FC<FeedbackProps> = ({ data, anchor }) => {
  const { feedbackQuestion, thankYouMessage, miscLabels } = data
  const [isThankYouVisible, setIsThankYouVisible] = useState(false)
  const [, setIsFeedbackSumbitted] = useLocalStorage(anchor, false)

  const handleFeedbackSubmission = () => {
    setIsThankYouVisible(true)
    setIsFeedbackSumbitted(true)
  }

  return (
    <FeedbackContainer className={isThankYouVisible ? 'hide' : ''}>
      <FeedbackQuestionContainer className={isThankYouVisible ? 'fade-out' : ''}>
        <FeedbackQuestion>{feedbackQuestion}</FeedbackQuestion>
        <FeedbackButtonsContainer>
          <Button
            id={`feedback-${anchor}-no`}
            aria-label={`negative-feedback-${anchor}`}
            variant="outline"
            onClick={handleFeedbackSubmission}
          >
            {miscLabels?.no}
          </Button>
          <Button
            id={`feedback-${anchor}-yes`}
            aria-label={`positive-feedback-${anchor}`}
            variant="outline"
            onClick={handleFeedbackSubmission}
          >
            {miscLabels?.yes}
          </Button>
        </FeedbackButtonsContainer>
      </FeedbackQuestionContainer>
      <ThankYouMessage className={isThankYouVisible ? 'fade-in' : ''}><CheckmarkIcon />{thankYouMessage}</ThankYouMessage>
    </FeedbackContainer>
  )
}

export default Feedback
