import styled from '@emotion/styled'
import r from '@utils/pxToRem'

const ButtonsContainer = styled.div`
  margin-bottom: ${r`40px`};

  & > button {
    &:first-of-type {
      margin-right: ${r`28px`};
    }
  }
`

const QuestionCounter = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.questionCounter[0]};
`

const QuestionContainer = styled.div<{ hasFailed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .rich-text {
    max-width: 47ch;
    & > h4 {
      padding-top: 5px;
      font-family: ${({ theme }) => theme.fonts.tondo};
      font-size: ${({ theme }) => theme.fontSizes.questionText[0]};
      line-height: 1.34;
      margin-bottom: ${r`40px`};
    }

    & > p {
      font-size: ${({ theme }) => theme.fontSizes.questionCounter[0]};
    }
  }

  ${props =>
    props.hasFailed &&
    `
    filter: saturate(0);
    opacity: 0.5;
    pointer-events: none;
  `}
`

const FailureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${r`40px`};

  & > img {
    margin-top: ${r`40px`};
    margin-bottom: ${r`40px`};
  }

  & > h4 {
    padding-top: 5px;
    font-family: ${({ theme }) => theme.fonts.tondo};
    font-size: ${({ theme }) => theme.fontSizes.failureHeading[0]};
    line-height: 1.34;
    margin-bottom: ${r`10px`};
  }

  & > .rich-text {
    max-width: 53ch;

    & > p {
      margin-bottom: ${r`40px`};

      &:last-of-type {
        margin-bottom: ${r`0px`};
      }
    }
  }
`

const ResetButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.graphik};
  font-size: ${({ theme }) => theme.fontSizes.questionCounter};
  color: ${({ theme }) => theme.colors.linkBlue};
  background: transparent;
  line-height: 1.6;
  text-decoration: none;
  padding: 0;
  transition: all 0.15s;
  cursor: pointer;
  border: none;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.purple};
  }

  & svg {
    padding-left: ${r`6px`};
  }
`

export { ButtonsContainer, QuestionContainer, QuestionCounter, FailureContainer, ResetButton }
