import styled from '@emotion/styled'
import {
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
} from '@reach/accordion'
import { over, under } from '@theme/media'
import r from '@utils/pxToRem'

const AccordionHeaderContainer = styled.header`
  display: flex;
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  width: ${r`82vw`};
  
  margin-bottom: ${r`16px`};

  ${under.laptop`
    width: ${r`100%`};
    
  `}
`

const AccordionButtonContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;

  p {
    padding-right: ${r`20px`};
    margin-bottom: ${r`0`};
    font-family: ${({ theme }) => theme.fonts.graphik};
    font-weight: ${({ theme }) => theme.fontWeights.navigation};
    color: ${({ theme }) => theme.colors.purple};
  }
`

const AccordionHeader = styled(ReachAccordionButton)`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.tondo};
  font-size: ${({ theme }) => theme.fontSizes.accordionHeading[1]};
  font-weight: ${({ theme }) => theme.fontWeights.accordionHeading};
  line-height: ${({ theme }) => theme.lineHeights.accordionHeading};
  color: ${({ theme }) => theme.colors.textBlack};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;
  padding: ${r`40px 50px`};
  margin-top: ${r`30px`};
  border-radius: ${r`12px`};
  text-align: left;

  &[data-state='open'] {
    .collapsedLabel{display: none;}
    .openLabel{display: block;}

    ${under.laptop`
      .openLabel{display: none;}
    `}

    .chevron-icon {
      svg circle, path {
        fill: ${({ theme }) => theme.colors.purple};
        transition: fill, ease-in-out;
        transition-duration: ${({ theme }) => theme.transistionDurations.accordion}s;
      }
      svg path {
        stroke: ${({ theme }) => theme.colors.white};
        transform: rotate(0deg);
        transition: stroke, transform, ease-in-out;
        transition-duration: ${({ theme }) => theme.transistionDurations.accordion}s;
      }
    }
  }

  &[data-state='collapsed'] {
    .collapsedLabel{display: block}
    .openLabel{display: none}

    ${under.laptop`
      .collapsedLabel{display: none;}
    `}

    .chevron-icon {
      svg circle, path {
        fill: ${({ theme }) => theme.colors.white};
        transition: fill, ease-in-out;
        transition-duration: ${({ theme }) => theme.transistionDurations.accordion}s;
      }
      svg path {
        stroke: ${({ theme }) => theme.colors.purple};
        transform: rotate(-180deg);
        transition: stroke, transform, ease-in-out;
        transition-duration: ${({ theme }) => theme.transistionDurations.accordion}s;
      }
    }
  }

  &[data-state='collapsed']:hover {
    transform: scale(${r`102%`});
    transition: transform ease-in-out;
    transition-duration: ${({ theme }) => theme.transistionDurations.buttonHover}s;
    ${under.laptop`
      transform: scale(100%);
      transition: none;
    `}
  }

  &[data-state='collapsed']:focus {
    transform: scale(${r`100%`});
    transition: transform, ease-in-out;
    transition-duration: ${({ theme }) => theme.transistionDurations.buttonHover}s;
  }

  ${({ theme }) =>
    over.tablet`
      font-size: ${theme.fontSizes.accordionHeading[0]};
  `};

  ${({ theme }) =>
    under.laptop`
    transistion: none !important;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    background-color: ${theme.colors.white};
    border-style: solid;
    border-width: ${r`1px 0 0 0`};
    border-color: ${theme.colors.lightGrey};
    padding: ${r`19px 0px`};
    margin: ${r`0`};
    margin-top: ${r`0px`};
    border-radius: ${r`0px`};
  `};

`

interface AccordionPanelProps {
  height: string;
}

const AccordionPanel = styled(ReachAccordionPanel)<AccordionPanelProps>`
  background-color: ${({ theme }) => theme.colors.white};
  width: ${r`82vw`};
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  border-radius: ${r`12px`};
  overflow: hidden;
  box-sizing: border-box;
  /* margin-top: ${r`-16px`}; */
  display: block;

  .rich-text {
    padding-bottom: 2rem;

    & > p, ul, ol {
      margin-bottom: 1rem;
    }

    b {
      font-weight: bold;
    }
    
    ${under.laptop`
      & p {
        font-size: 1rem;
      }
    `}
  }

  .panel-wrapper {
    box-sizing: border-box;
    padding: ${r`50px 50px`};
    margin: ${r`0`};
    /* margin-top: ${r`40px`}; */
  }

  &[data-state="open"] {
    height: ${(p: AccordionPanelProps) => p.height};
    transition: height ease-in-out;
    transition-duration: ${({ theme }) => theme.transistionDurations.accordion}s;

    & > div {
      opacity: 1;
      transform: translate(${r`0, 0px`});
      transition: opacity, transform, ease-in-out;
      transition-duration: ${({ theme }) => theme.transistionDurations.accordionBody}s;
      /* transition-delay: ${({ theme }) => theme.transistionDurations.accordionBody}s; */
    }
  }

  &[data-state="collapsed"] {
    height: ${r`0px`};
    transition: height ease-in-out;
    transition-duration: ${({ theme }) => theme.transistionDurations.accordion}s;

    & > div {
      opacity: 0;
      transform: translate(${r`0, 40px`});
      transition: opacity, transform, ease-in-out;
      transition-duration: ${({ theme }) => theme.transistionDurations.accordion}s;
    }
  }

  ${under.laptop`
    width: ${r`100%`};
    .panel-wrapper {
      padding: ${r`16px 0 50px`};
      margin-top: ${r`0px`};
    }
    border-radius: 0;
  `}
`

export { AccordionHeaderContainer, AccordionButtonContainer, AccordionHeader, AccordionPanel }
