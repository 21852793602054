import React from 'react'

const ResetIcon: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7984 1.87927H19.3126C19.8326 1.87927 20.2523 1.45831 20.2523 0.939636C20.2523 0.42033 19.8326 0 19.3126 0H13.205C12.6851 0 12.2654 0.42033 12.2654 0.939636V7.41874C12.2654 7.93804 12.6851 8.35837 13.205 8.35837C13.7249 8.35837 14.1446 7.93804 14.1446 7.41874V3.21856C17.6902 4.52466 20.1207 7.96247 20.1207 11.7786C20.1207 16.8088 16.0302 20.9006 11 20.9006C5.96982 20.9006 1.87927 16.8088 1.87927 11.7786C1.87927 7.92426 4.31606 4.46891 7.94305 3.17973C8.43166 3.00621 8.6885 2.46874 8.5131 1.98013C8.34396 1.49026 7.80524 1.23468 7.31663 1.40883C2.93793 2.96361 0 7.13121 0 11.7786C0 17.8449 4.93622 22.7799 11 22.7799C17.0638 22.7799 22 17.8449 22 11.7786C22 7.54653 19.5256 3.6959 15.7984 1.87927Z"
      fill="currentColor"
    />
  </svg>
)

export default ResetIcon
