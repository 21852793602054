import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Provider } from 'jotai'
import Layout from '@components/Layout'
import Seo from '@components/Seo'
import AccordionSection from '@components/PageComponents/AccordionSection'
import RenewalWizardSection from '@components/PageComponents/RenewalWizardSection'
import HeroSection from '@components/PageComponents/HeroSection'
import StepsSection from '@components/PageComponents/StepsSection'
import {
  HomePageQuery,
  ContentfulAccordion,
  ContentfulWizard,
  ContentfulStep,
} from 'typings/contentful-queries'
import PageWrapper from '@components/PageComponents/PageWrapper'
import RichText from '@components/RichText'

interface IndexProps {
  data: HomePageQuery
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    ogImage,
    accordionSection,
    renewalWizard,
    introHeader,
    introDescription,
    stepsHeading,
    renewalSteps,
  } = data.contentfulHomePage ?? {}

  const { isDisplayed, heading, body, graphic } = data?.contentfulOutagePage ?? {}
  return (
    <Layout lang={'en-US'}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription?.metaDescription ?? ''} />
      </Helmet>
      <Seo
        ogUrl="https://renew.icbc.com"
        metaTitle={metaTitle ?? ''}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaImage={ogImage?.file?.url ?? ''}
      />
      <Provider>
        {isDisplayed ? (
          <PageWrapper>
            <div>
              <h1>{heading}</h1>
              <RichText data={body} />
            </div>
            <img src={graphic?.file?.url ?? ''} alt={graphic?.description ?? ''} />
          </PageWrapper>
        ) : (
          <main>
            <HeroSection
              introHeader={introHeader as string}
              introDescription={introDescription as string}
            />
            <StepsSection
              data={renewalSteps as ContentfulStep[]}
              heading={stepsHeading?.childMarkdownRemark?.html ?? ''}
            />
            <RenewalWizardSection data={renewalWizard as ContentfulWizard} />
            <AccordionSection data={accordionSection as ContentfulAccordion[]} />
          </main>
        )}
      </Provider>
    </Layout>
  )
}

export default IndexPage

// Example of how to query content from Contentful is as follows. Examples of how to perform for image and
// rich content queries are included.

// NOTE:
// Codegen will cause issues when attempting to generate types for '...GatsbyContentfulFluid_withWebP' and
// potentially other graphql fragments (lines starting with '...'). In that case, replace those lines with
// the following lines for the image fragment and regenerate the types:

//   base64
//   aspectRatio
//   src
//   srcSet
//   srcWebp
//   srcSetWebp
//   sizes

export const pageQuery = graphql`
  query HomePage {
    contentfulHomePage(contentful_id: { eq: "1eRmq6mS2BN3cuvxw894IY" }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      ogImage {
        file {
          url
        }
      }
      introHeader
      introDescription
      stepsHeading {
        childMarkdownRemark {
          html
        }
      }
      renewalSteps {
        title
        description {
          raw
        }
        illustrationImage {
          description
          file {
            url
          }
        }
        lottieData {
          internal {
            content
          }
        }
        illustrationAltText
        stepNumberKeyword
        stepNumberOverride
      }
      renewalWizard {
        initialStep {
          raw
        }
        skipText
        skipLinkText
        skipQuestionsLinkUrl
        successRedirectUrl
        ctaLabel
        questions {
          questionText {
            raw
            references {
              ... on ContentfulTooltip {
                contentful_id
                __typename
                tooltipBody {
                  tooltipBody
                }
              }
            }
          }
          skipText {
            raw
          }
          failureTitle
          failureBody {
            raw
            references {
              ... on ContentfulTooltip {
                contentful_id
                __typename
                tooltipBody {
                  tooltipBody
                }
              }
            }
          }
          failureIcon {
            file {
              url
            }
            description
          }
          miscLabels {
            yes
            no
            startOver
            currentPosition
          }
        }
        successHeading
        successDescription {
          raw
        }
        legalCheckboxText {
          raw
        }
        optionLabel
        successOptions {
          name
          optionBody {
            raw
          }
          cta {
            text
            destination
          }
          brandLogo {
            file {
              url
            }
            description
          }
        }
        successIcon {
          file {
            url
          }
          description
        }
      }
      accordionSection {
        title
        anchor
        bodyText {
          raw
          references {
            ... on ContentfulTooltip {
              contentful_id
              __typename
              tooltipBody {
                tooltipBody
              }
            }
          }
        }
        feedbackQuestion
        thankYouMessage
        miscLabels {
          seeMore
          seeLess
          yes
          no
        }
      }
    }
    contentfulOutagePage {
      isDisplayed
      heading
      body {
        raw
      }
      graphic {
        description
        file {
          url
        }
      }
    }
  }
`
